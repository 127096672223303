import style from './App.module.css';
import {Route, Routes, useNavigate} from 'react-router-dom'
import {ButtonComp} from "../shared/ui/Button/ButtonComp";
import {LoginPage} from "../pages/Login/ui/LoginPage";
import {MainComp} from "../pages/Main/MainComp";
import {Toaster} from "react-hot-toast";
import {RegisterPage} from "../pages/Login/ui/RegisterPage";
import {AccountConfirmComp} from "../pages/AccountConfirm/AccountConfirmComp";
import {useEffect, useState} from "react";
import {LogoutPage} from "../pages/Login/ui/LogoutPage";
import {getLocalStorageValue} from "../shared/lib/LocalStorage";
import {AUTH} from "../shared/const/Structures";
import {getUserInfo} from "./AppActions";
import {FeedingPage} from "../pages/Feeding";
import {DoingsPage} from "../pages/Doings";
import {CashWrapper} from "../pages/Cash";
import {AdminPage} from "../pages/Admin";
import {CookingWrapper} from "../pages/Cooking";
import {InfoWrapper} from "../pages/Info/ui/InfoWrapper";
import {ICONS} from "../shared/ui/img/icons/icons";
import {ModalWindow} from "../shared/ui/ModalWindow/ModalWindow";
import {useAppData} from "../shared/hooks/useAppData";

const App = () => {
    const {t, switchLanguage, currentLang} = useAppData()
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [userData, setUserData] = useState({})
    const history = useNavigate()
    const [currentPage, setCurrentPage] = useState(window.location.pathname)

    const loginCheck = () => {
        if (!isAuthenticated) {
            let token = getLocalStorageValue(AUTH.TOKEN)
            if (!!token) {
                getUserInfo(token, setIsAuthenticated, setUserData)
            }
        }
    }

    useEffect(loginCheck,[isAuthenticated])

    function hasRole(role) {
        return !!userData['roles'] && userData['roles'].includes(role)
    }

    const changePage = (target) => {
        history(target)
        setCurrentPage(window.location.pathname)
    }

    return <div className={style.wrapper}>
        <Toaster/>
        <ModalWindow/>
        <div className={style.topLine}>
            <div className={style.buttonGroup}>
                <ButtonComp
                    tooltipText={t["page_main_tooltip"]}
                    text={t["page_main"]}
                    onClick={() => {
                        changePage("/main")
                    }}
                    isActive={currentPage.startsWith("/main")}
                />
                {hasRole("PAGE_INFO") && <ButtonComp
                    tooltipText={t["page_info_tooltip"]}
                    text={t["page_info"]}
                    onClick={() => {
                        changePage("/info")
                    }}
                    isActive={currentPage.startsWith("/info")}
                />}
                {hasRole("PAGE_FEEDING") && <ButtonComp
                    tooltipText={t["page_feeding_tooltip"]}
                    text={t["page_feeding"]}
                    onClick={() => {
                        changePage("/feeding")
                    }}
                    isActive={currentPage.startsWith("/feeding")}
                />}
                {hasRole("PAGE_DOINGS") && <ButtonComp
                    tooltipText={t["page_doings_tooltip"]}
                    text={t["page_doings"]}
                    onClick={() => {
                        changePage("/doings")
                    }}
                    isActive={currentPage.startsWith("/doings")}
                />}
                {hasRole("PAGE_CASH") && <ButtonComp
                    tooltipText={t["page_cash_tooltip"]}
                    text={t["page_cash"]}
                    onClick={() => {
                        changePage("/cash")
                    }}
                    isActive={currentPage.startsWith("/cash")}
                />}
                {hasRole("ADMIN") && <ButtonComp
                    tooltipText={t["page_admin_tooltip"]}
                    text={t["page_admin"]}
                    onClick={() => {
                        changePage("/admin")
                    }}
                    isActive={currentPage.startsWith("/admin")}
                />}
                {hasRole("PAGE_COOKING") && <ButtonComp
                    tooltipText={t["page_cooking_tooltip"]}
                    text={t["page_cooking"]}
                    onClick={() => {
                        changePage("/cooking")
                    }}
                    isActive={currentPage.startsWith("/cooking")}
                />}
            </div>

            <div className={style.additionalButtons}>
                <ButtonComp
                    text={currentLang === "en" ? "en" : "ru"}
                    tooltipText={currentLang === "en" ? t["switch_to_ru"] : t["switch_to_en"]}
                    onClick={() => {
                        currentLang === "en"
                            ?
                            switchLanguage("ru")
                            :
                            switchLanguage("en")
                    }}
                />

                <ButtonComp
                    tooltipText={t["login"]}
                    text={userData['login']}
                    onClick={() => history("/login")}
                    icon={ICONS.account}
                />
            </div>
        </div>

        <div className={style.pageContent}>
            <Routes>
                <Route path={"/"} element={<MainComp/>}/>
                <Route path={"/login"} element={
                    isAuthenticated
                        ? <LogoutPage
                            setIsAuthenticated={setIsAuthenticated}
                            setUserData={setUserData}
                        />
                        : <LoginPage
                            setIsAuthenticated={setIsAuthenticated}
                            setUserData={setUserData}
                        />}
                />
                <Route path={"/register"} element={<RegisterPage/>}/>
                <Route path={"/emailConfirm/:secret"} element={<AccountConfirmComp/>}/>
                <Route path={"/main"} element={<MainComp/>}/>
                {hasRole("PAGE_INFO") && <Route path={"/info"} element={<InfoWrapper/>}/>}
                {hasRole("PAGE_INFO") && <Route path={"/info/:visibility"} element={<InfoWrapper/>}/>}
                {hasRole("PAGE_INFO") && <Route path={"/info/:visibility/:spaceId"} element={<InfoWrapper/>}/>}
                {hasRole("PAGE_INFO") && <Route path={"/info/:visibility/:spaceId/:pageId"} element={<InfoWrapper/>}/>}
                {hasRole("PAGE_FEEDING") && <Route path={"/feeding"} element={<FeedingPage/>}/>}
                {hasRole("PAGE_DOINGS") && <Route path={"/doings"} element={<DoingsPage/>}/>}
                {hasRole("PAGE_CASH") && <Route path={"/cash"} element={<CashWrapper/>}/>}
                {hasRole("PAGE_CASH") && <Route path={"/cash/:tab"} element={<CashWrapper/>}/>}
                {hasRole("ADMIN") && <Route path={"/admin"} element={<AdminPage/>}/>}
                {hasRole("PAGE_COOKING") && <Route path={"/cooking"} element={<CookingWrapper/>}/>}
                {hasRole("PAGE_COOKING") && <Route path={"/cooking/:category"} element={<CookingWrapper/>}/>}
            </Routes>
        </div>
    </div>
}

export default App;