import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import {BrowserRouter} from "react-router-dom";
import {ModalDataProvider} from "./shared/hooks/useModal";
import {AppDataProvider} from "./shared/hooks/useAppData";

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <AppDataProvider>
            <ModalDataProvider>
                <App/>
            </ModalDataProvider>
        </AppDataProvider>
    </BrowserRouter>
)
