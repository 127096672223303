import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {deletePage} from "../../../api/request";
import toast from "react-hot-toast";
import React from "react";
import {useNavigate} from "react-router-dom";
import style from "./DeletePageModal.module.css"
import {useModal} from "../../../../../shared/hooks/useModal";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const DeletePageModal = ({itemData, infoData, pageData, space}) => {
    const {t} = useAppData()
    const {modal} = useModal()
    const history = useNavigate()

    return <div className={style.wrapper}>
        <div className={style.topText}>
            <h3>{t["info_delete_page"]}?</h3>
        </div>
        <div className={style.middleText}>
            {t["This_will_delete"]} <strong className={style.middleTextPart}>&nbsp;{itemData.title}</strong>
        </div>
        <div className={style.buttonWrapper}>
            <div className={style.button}>
                <ButtonComp
                    text={t["cancel"]}
                    onClick={() => {modal.close()}}
                />
            </div>
            <div className={style.button}>
                <ButtonComp
                    text={t["delete"]}
                    onClick={() => {
                        deletePage(space.selectedSpace.id, pageData.parentId, () => {
                            pageData.updatePageList()
                            pageData.setParentId(undefined)
                            toast.success(t["deleted"])
                            history(infoData.generateHistoryPathByPageId(null, space.selectedSpace))
                            modal.close()
                        })
                    }}
                />
            </div>
        </div>
    </div>
}