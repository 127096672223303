import { createContext, useContext, useEffect, useState } from "react";

const AppDataContext = createContext(null);

export const useAppData = () => {
    return useContext(AppDataContext);
};

export const AppDataProvider = ({ children }) => {
    const [currentLang, setCurrentLang] = useState(() => {
        const savedLang = localStorage.getItem('currentLang')
        return savedLang || 'en'
    })
    const [t, setTranslations] = useState({})

    const switchLanguage = (lang) => {
        setCurrentLang(lang);
        localStorage.setItem('currentLang', lang)
    }

    useEffect(() => {
        const loadTranslations = () => {
            fetch(`/${currentLang}.json`)
                .then(response => response.json())
                .then(data => setTranslations(data))
                .catch(error => {
                    console.error('Error loading translations:', error)
                })
        }

        loadTranslations()
    }, [currentLang])

    const combinedData = {
        currentLang,
        switchLanguage,
        t,
    }

    return<AppDataContext.Provider value={combinedData}>{children}</AppDataContext.Provider>
}
