import {useRef, useState} from "react";
import {ICONS} from "../img/icons/icons";
import {RawButton} from "../RawButton/RawButton";
import style from "./DropdownMenu.module.css"
import {useClickOutside} from "../../hooks/useClickOutside";
import {useInfo} from "../../../pages/Info/lib/hooks/UseInfo";
import {useAppData} from "../../hooks/useAppData";

export const DropdownMenu = ({ options, id}) => {
    const {t} = useAppData()
    const {pageData} = useInfo()
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null)

    useClickOutside(menuRef, setIsOpen)

    const handleSelect = (action) => {
        setIsOpen(false)
        action()
    }

    return <div ref={menuRef} className={style.wrapper}>
        <RawButton
            content={
                <div key={id} className={style.dropdownButton}>
                    {ICONS.dotMenu}
                </div>
            }
            onClick={() => {
                setIsOpen(!isOpen)
                pageData.setParentId(id ? id.toString() : undefined)
            }}
        />
        {isOpen && (
            <div className={style.dropdownMenu}>
                <div className={style.header}>
                    {t["page"]}
                </div>
                <ul className={style.menuList}>
                    {options.map((e) => (
                        <li className={style.menuItem} key={e.id} onClick={() => {
                            handleSelect(e.action)
                        }}>
                            {e.title}
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </div>
}