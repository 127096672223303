import {ButtonComp} from "../../Button/ButtonComp";
import React from "react";
import {setCaretPosition} from "../lib/CaretPosition";
import {ICONS} from "../../img/icons/icons";
import {useAppData} from "../../../hooks/useAppData";

export const CodeBlock = ({syncText}) => {
    const {t} = useAppData()

    const insertCodeBlock = () => {
        let selection = window.getSelection().anchorNode
        if (selection.nodeName === "#text") {
            let textNode = selection
            selection = selection.parentNode;

            let textBefore = textNode.textContent.slice(0, window.getSelection().getRangeAt(0).startOffset)
            let textAfter = textNode.textContent.slice(window.getSelection().getRangeAt(0).startOffset)

            let textNodeBefore = document.createTextNode(textBefore);
            let textNodeAfter = document.createTextNode(textAfter);

            let code = document.createElement("code");
            code.textContent = "";

            selection.removeChild(textNode);
            selection.appendChild(textNodeBefore);
            selection.appendChild(code);

            let space = document.createTextNode("\u00A0"); // &nbsp;
            selection.appendChild(space);

            selection.appendChild(textNodeAfter);

            setCaretPosition(code, { start: 0, end: 0 });
            return
        }

        if (['TD', 'DIV'].includes(selection.nodeName)) {
            let code = document.createElement("code")
            code.textContent = ""

            if (selection.childNodes.length === 1) {
                selection.childNodes.forEach(e => {
                    if (e.nodeName === "BR")
                        e.remove()
                })
            }

            selection.appendChild(code)
            setCaretPosition(code, { start: 0, end: 0 })

            const newLine = document.createElement("div");
            newLine.innerHTML = "&nbsp;";

            selection.appendChild(newLine);
        }
    }

    return <ButtonComp
        icon={ICONS.codeBlock}
        onClick={() => {
            insertCodeBlock()
            syncText()
        }}
        tooltipText={t["code_block"]}
    />
}