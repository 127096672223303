import {sendGetMsg} from "../../shared/api/SendMsg";
import toast from "react-hot-toast";
import {URLS} from "../../shared/const/Backend";

export function confirmEmail(string, setStep, setText, history) {
    sendGetMsg(URLS.USER + "/emailConfirm",
        {"key": string},
        () => {
            setStep("success")
            setText("Success. Now you can login")
            toast.success("Success. Now you can login", { duration: 10000 })
            history("/login")
        },
        (e) => {
            setStep("error")
            setText(e)
        }
    )
}