import style from "./Nutrition.module.css"
import React, {useEffect, useRef, useState} from "react";
import {useCooking} from "../../../lib/hooks/UseCooking";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {useParams} from "react-router-dom";
import {getReceiptData} from "../../../api/Request";
import {CookingNormalMode} from "../CookingReceiptDetails/CookingNormalMode";
import {CookingEditMode} from "../CookingEditMode/CookingEditMode";
import {useModal} from "../../../../../shared/hooks/useModal";
import {CreateReceiptModal} from "../CookingModal/CreateReceiptModal";
import {CookingGroupItems} from "../CookingGroupItems/CookingGroupItems";
import {CookingReceiptItem} from "../CookingReceiptItem/CookingReceiptItem";
import {Resizer} from "../../../../../shared/ui/Resizer/Resizer";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const Nutrition = () => {
    const {t} = useAppData()
    const {modal} = useModal()
    const {selectFood, setReceiptData, receiptData, addReceipt, dictionaryUnit, selectedCategory} = useCooking()
    const refBox = useRef(null)
    const {category} = useParams()

    const [editMode, setEditMode] = useState(false)
    const [selectedReceipt, setSelectedReceipt] = useState(null)

    const [sidebarWidth, setSidebarWidth] = useState();

    let selectedReceiptByCategory = receiptData.filter((e) => e.categoryId === selectedCategory.id)

    const receiptArray = []

    const addReceiptWithoutGroup = (receipt) => {
        const receiptWithoutGroup = {
            type: "element",
            obj: receipt,
            id: receipt.id
        }
        receiptArray.push(receiptWithoutGroup)
    }

    const addGroup = (receipt) => {
        const existingGroup = receiptArray.filter(e => e.type === "group" && e.name === receipt.groupName)

        if (existingGroup.length === 0) {
            const group = {
                type: "group",
                obj: [receipt],
                name: receipt.groupName,
                id: receipt.id
            }
            receiptArray.push(group)
        } else {
            existingGroup[0].obj.push(receipt)
        }
    }

    selectedReceiptByCategory.forEach(e => {
        if (e.groupName) {
            addGroup(e)
        } else {
            addReceiptWithoutGroup(e)
        }
    })

    useEffect(() => {
        getReceiptData(setReceiptData)
    }, [])

    const handleBackClick = () => {
        selectFood(null)
    }

    return <div className={style.wrapper}>
        <div className={style.topLine}>
            <div className={style.topButton}>
                <ButtonComp
                    text={t["add"]}
                    onClick={() => {
                        modal.open(<CreateReceiptModal
                            addReceipt={addReceipt}
                            dictionaryUnit={dictionaryUnit}
                            selectedCategory={selectedCategory}
                        />)
                    }}
                />
            </div>
            {t[category]}
            <div className={style.topButton}>
                <ButtonComp
                    text={t["back"]}
                    onClick={handleBackClick}
                />
            </div>
        </div>
        <div className={style.content}>
            <div ref={refBox} className={style.sideMenu}>
                <div className={style.sideMenuContent}>
                    {receiptArray.map(e => e.type === "group"
                        ?
                        <CookingGroupItems
                            key={e.id}
                            group={e}
                            setSelectedReceipt={setSelectedReceipt}
                        />
                        :
                        <CookingReceiptItem
                            key={e.id}
                            receipt={e.obj}
                            setSelectedReceipt={setSelectedReceipt}
                        />
                    )}
                </div>
                <Resizer
                    refBox={refBox}
                    width={sidebarWidth}
                    setWidth={setSidebarWidth}
                />
            </div>
            <div className={style.receiptInfo} style={{marginLeft: sidebarWidth}}>
                {selectedReceipt && (
                    !editMode ?
                    <CookingNormalMode
                        setEditMode={setEditMode}
                        receipt={selectedReceipt}
                    />
                    :
                    <CookingEditMode
                        setEditMode={setEditMode}
                        receipt={selectedReceipt}
                        setSelectedReceipt={setSelectedReceipt}
                    />
                )}
            </div>
        </div>
    </div>
}