import style from "../../InfoPage.module.css";
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {deleteSpace, updateSpace} from "../../../api/request";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const EditSpaceModal = ({
                                   closeModal,
                                   currentSpace,
                                   updateSpaceList
                                 }) => {
    const {t} = useAppData()
    const [original, setOriginal] = useState(currentSpace['title'])

    // eslint-disable-next-line
    useEffect(() => {setOriginal(currentSpace['title'])}, [currentSpace['title']])

    if (!currentSpace) {
        closeModal()
        return <div/>
    }
    function exitAndDiscard() {
        currentSpace['title'] = original
        closeModal()
    }


    return <div className={style.modalWindow}>
        <h3>{t["info_edit_space"]}</h3>
        <div className={style.modalElement}>
            <InputTextComp
                title={t["info_space_name"]}
                defaultText={currentSpace['title']}
                acceptChanges={(newVal) => {
                    currentSpace['title'] = newVal
                }}
            />
        </div>
        <div className={style.modalElement}>
            {/* TODO: make confirm dialogue */}
            <ButtonComp
                text={t["delete"]}
                minWidth={"5em"}
                tooltipText={t["info_space_delete_tooltip"]}
                onClick={() => {
                    deleteSpace(currentSpace['id'], updateSpaceList)
                    closeModal()
                }}
            />

            <ButtonComp
                text={t["cancel"]}
                minWidth={"5em"}
                tooltipText={t["info_space_cancel_tooltip"]}
                onClick={exitAndDiscard}
            />
            <ButtonComp
                text={t["apply"]}
                minWidth={"5em"}
                tooltipText={t["info_space_apply_tooltip"]}
                onClick={() => {
                    console.log("New object: ", currentSpace)
                    updateSpace(currentSpace, (e) => {
                        updateSpaceList(e)
                        toast.success("Updated")
                    })
                    // TODO: change active space name to new
                    closeModal()
                }}
            />
        </div>
    </div>
}