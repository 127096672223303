import {ButtonComp} from "../../../Button/ButtonComp";
import {ICONS} from "../../../img/icons/icons";
import {useAppData} from "../../../../hooks/useAppData";

export const InsertTable = ({refWorkSpace, syncText}) => {
    const {t} = useAppData()

    const insertTable = () => {
        document.execCommand('insertHTML', false, '<table><tr><td><br></td><td><br></td></tr><tr><td><br></td><td><br></td></tr></table>')
        refWorkSpace.current.focus()

        const table = refWorkSpace.current.querySelector('table');

        if (table) {
            const firstCell = table.querySelector('td');
            if (firstCell) {
                const range = document.createRange();
                range.setStart(firstCell, 0);
                range.collapse(true);
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    }

    return <ButtonComp
        text={ICONS.table}
        tooltipText={t["insert_table"]}
        onClick={() => {
            insertTable()
            syncText()
        }}
    />
}