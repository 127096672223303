import React from "react";
import style from "./DatePicker.module.css";
import {InputTextComp} from "../../InputText/InputTextComp";
import {useAppData} from "../../../hooks/useAppData";

export const DrawYear = ({onClickHandler, defaultYear}) => {
    const {t} = useAppData()

    return <div className={style.year}>
        <InputTextComp
            acceptChanges={newVal => {onClickHandler(newVal)}}
            title={t["year"]}
            defaultText={defaultYear}
        />
    </div>

}