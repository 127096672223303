import style from "../../InfoPage.module.css";
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {createSpace} from "../../../api/request";
import React, {useState} from "react";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const CreateSpaceModal = ({
                                     closeModal,
                                     isPrivate,
                                     updateSpaceList,
                                 }) => {
    const [newSpaceName, setNewSpaceName] = useState("")
    const {t} = useAppData()

    return <div className={style.modalWindow}>
        <h3>{t["info_create_space"]}</h3>
        <div className={style.modalElement}>
            <InputTextComp
                title={t["info_space_name"]}
                acceptChanges={(newVal) => {
                    setNewSpaceName(newVal)
                }}
            />
        </div>
        <div className={style.modalElement}>
            <ButtonComp
                text={t["cancel"]}
                minWidth={"5em"}
                tooltipText={t["info_space_cancel_tooltip"]}
                onClick={closeModal}
            />
            <ButtonComp
                text={t["apply"]}
                minWidth={"5em"}
                tooltipText={t["info_create_space"]}
                onClick={() => {
                    createSpace(newSpaceName,
                        isPrivate,
                        () => {
                            updateSpaceList()
                            // TODO: select new space as active
                        })
                    closeModal()
                }}
            />
        </div>
    </div>
}
