import style from "./ViewTransaction.module.css"
import {TransactionLine} from "./TransactionLine";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const ViewTransaction = ({transactionList}) => {
    const {t} = useAppData()

    if (!transactionList || !transactionList.length) {
        return <div className={style.wrapper}>{t["no_data_available"]}</div>;
    }

    return <div className={style.wrapper}>
        <table>
            <thead>
            <tr>
                <th>{t["done"]}</th>
                <th>{t["product"]}</th>
                <th> </th>
                <th>{t["value"]}</th>
            </tr>
            </thead>
            <tbody>
            {transactionList[0]["transactions"].map((e) => (
                <TransactionLine
                    transaction={e}
                    key={e.id}
                />
            ))}
            </tbody>
        </table>
    </div>
}