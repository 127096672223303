import {useEffect, useState} from "react";
import {isInsideStyle} from "../../../lib/TextHtmlHelper";
import {addGlobalEventListener, dispatchGlobalEvent, removeGlobalEventListener} from "../../../lib/Listener";
import style from "./Buttons.module.css";
import {ButtonComp} from "../../Button/ButtonComp";
import {ICONS} from "../../img/icons/icons";
import {useAppData} from "../../../hooks/useAppData";

export const AlignCenterButton = ({refWorkSpace}) => {
    const {t} = useAppData()
    const [activeButton, setActiveButton] = useState(false)

    const handleGlobalEvent = () => {
        setActiveButton(isInsideStyle(refWorkSpace, "textAlign", 'center'))
    }

    useEffect(() => {

        addGlobalEventListener(handleGlobalEvent)

        return () => {
            removeGlobalEventListener(handleGlobalEvent)
        }
    }, [])

    return <div className={style.button}>
        <ButtonComp
            icon={ICONS.textCenter}
            onClick={() => {
                document.execCommand('justifyCenter', false,  '')
                refWorkSpace.current.focus()
                dispatchGlobalEvent("caretIsEdited")
            }}
            tooltipText={t["align_center"]}
            isActive={activeButton}
        />
    </div>
}