import style from "./Buttons.module.css"
import {useId, useRef, useState} from "react";
import {calcCaretPosition, restoreCaretPosition} from "../lib/CaretPosition";
import {useClickOutside} from "../../../hooks/useClickOutside";
import {CompactPicker} from "react-color";
import {Tooltip} from "react-tooltip";
import {useAppData} from "../../../hooks/useAppData";

export const TextColor = ({syncText}) => {
    const {t} = useAppData()
    const [showColorPicker, setShowColorPicker] = useState(false)
    const refColors = useRef(null)
    const [savedSelection, setSavedSelection] = useState(null)
    const [currentColor, setCurrentColor] = useState('#f44e3b')
    const id = useId()

    useClickOutside(refColors, setShowColorPicker)

    const toggleColorPicker = () => {
        setSavedSelection(calcCaretPosition())
        setShowColorPicker(!showColorPicker)
    }

    const handleColorChange = (color) => {
        setCurrentColor(color.hex)
        restoreCaretPosition(savedSelection)
        document.execCommand('foreColor', false, color.hex)
        syncText()
        setSavedSelection(calcCaretPosition())
    }

    return <div ref={refColors} className={style.wrapper}>
        <div data-tooltip-id={id} className={style.topElement}
             onClick={() => document.execCommand('foreColor', false, currentColor)}
        >
            <svg width="30" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke={currentColor}
                      d="M18.97021,19.75732,15.34912,5.27246A2.9958,2.9958,0,0,0,12.43848,3h-.877A2.9958,2.9958,0,0,0,8.65088,5.27246L5.02979,19.75732a1.0001,1.0001,0,0,0,1.94042.48536L8.28082,15h7.43836l1.31061,5.24268a1.0001,1.0001,0,0,0,1.94042-.48536ZM8.78082,13l1.81049-7.24219A.99825.99825,0,0,1,11.56152,5h.877a.99825.99825,0,0,1,.97021.75781L15.21918,13Z"/>
            </svg>
        </div>
        {<Tooltip
            style={{zIndex:5}}
            place={"bottom"}
            content={t["text_color"]}
            id={id}
            delayShow={500}
        />}
        <div className={style.botElement} onClick={toggleColorPicker}>
            <svg height="8" width="30" xmlns="http://www.w3.org/2000/svg">
                <line x1="7" y1="6" x2="23" y2="6" stroke="#000000" strokeWidth="2"/>
            </svg>
        </div>
        {showColorPicker &&
            <div className={style.colorPickerContainer}>
                <div className={style.colorPicker}>
                    <CompactPicker
                        color={currentColor}
                        onChange={handleColorChange}
                    />
                </div>
            </div>
        }
    </div>
}