import {InvisibleInputText} from "../../../../../shared/ui/InvisibleInputText";
import style from "./ProductLine.module.css"
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {SelectComp} from "../../../../../shared/ui/Select/SelectComp";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const ProductLine = ({product, readOnly, deleteProduct, updateReceipt, receipt, dictionaryUnit}) => {
    const {t} = useAppData()
    const renderCashTr = (obj) => <td className={style.productTD}>{obj}</td>

    return <tr key={product.id}>
        {renderCashTr(<InvisibleInputText
            defaultText={product.productName}
            readOnly={readOnly}
            acceptChanges={(newValue) => {
                product.productName = newValue
                product.id = ""
                updateReceipt(receipt)
            }}
        />)}
        {readOnly !== true ? renderCashTr(<InvisibleInputText
            defaultText={product.value}
            readOnly={readOnly}
            acceptChanges={(newValue) => {
                product.value = newValue
                product.id = ""
                updateReceipt(receipt)
            }}
        />)
        : renderCashTr(<InvisibleInputText
                defaultText={product.value + " " + product.unitShort}
                readOnly={readOnly}
            />)
        }
        {readOnly !== true && renderCashTr(<SelectComp
            defaultText={product.unitShort}
            defaultSelectedValue={product.unitShort}
            values={dictionaryUnit}
            onChange={(e) => {
                product.unitShort = e.shortName
                product.id = ""
                updateReceipt(receipt)
            }}
            attributeTitle={'name'}
        />)}
        {readOnly !== true &&
            renderCashTr(<ButtonComp
                icon={ICONS.delete}
                tooltipText={t["delete"]}
                onClick={() => {
                    deleteProduct(product.id, receipt)
                }}
            />)
        }
    </tr>
}