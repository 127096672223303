import {ButtonComp} from "../../Button/ButtonComp";
import {ICONS} from "../../img/icons/icons";
import {useModal} from "../../../hooks/useModal";
import {LinkModal} from "./LinkModal";
import {calcCaretPosition} from "../lib/CaretPosition";
import {useAppData} from "../../../hooks/useAppData";

export const Link = ({syncText}) => {
    const {t} = useAppData()
    const {modal} = useModal()

    const handleLinkButtonClick = () => {
        const savedSelection = calcCaretPosition()
        modal.open(<LinkModal
            syncText={syncText}
            savedSelection={savedSelection}
        />)
    }


    return <div>
        <ButtonComp
            icon={ICONS.link}
            onClick={() => {
                handleLinkButtonClick()
            }}
            tooltipText={t["insert_link"]}
        />
    </div>
}