import style from "./ModalOptions.module.css"
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {CheckBox} from "../../../../../shared/ui/CheckBox";
import {useModal} from "../../../../../shared/hooks/useModal";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const ModalOptions = ({deleteReceipt, receipt, updateReceipt}) => {
    const {t} = useAppData()
    const {modal} = useModal()

    return <div className={style.wrapper}>
        <div className={style.header}>
            <h3>{t["options"]}</h3>
        </div>
        <div className={style.buttons}>
            <div className={style.button}>
                <ButtonComp
                    icon={ICONS.delete}
                    onClick={() => {
                        deleteReceipt(receipt.id)
                        modal.close()
                    }}
                    tooltipText={t["delete"]}
                />
            </div>
            <div className={style.buttons}>
                <CheckBox
                    defaultIsChecked={receipt.isPublic}
                    onChange={(newValue) => {
                        receipt.isPublic = newValue
                        updateReceipt(receipt)
                    }}
                    tooltipText={receipt.isPublic === true ? t["is_private"]: t["is_public"]}
                />
            </div>
        </div>
        <div className={style.cancel}>
            <ButtonComp
                text={t["cancel"]}
                onClick={() => {
                    modal.close()
                }}
            />
        </div>
    </div>
}