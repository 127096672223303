import style from "./ViewTransaction.module.css";
import {Comment} from "../CommentButton/Сomment";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {useCash} from "../../../lib/hooks/UseCash";
import {useState} from "react";
import {InvisibleInputText} from "../../../../../shared/ui/InvisibleInputText";
import {CheckBox} from "../../../../../shared/ui/CheckBox";
import {ProfitButton} from "../ProfitButton/ProfitButton";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const TransactionLine = ({transaction}) => {
    const {t} = useAppData()
    const renderCashTr = (obj) => <td className={style.cashTD}>{obj}</td>
    const {deleteTransaction, updateTransaction} = useCash()
    const [tempComment, setTempComment] = useState(transaction.comment);

    return <tr key={transaction.id}>
        {renderCashTr(<div className={style.checkBoxWrapper}><div className={style.checkBox}><CheckBox
             defaultIsChecked={transaction.completed}
             onChange={(newValue) => {
                 transaction.completed = newValue
                 updateTransaction(transaction)
             }}
        /></div></div>)}
        {renderCashTr(<InvisibleInputText
            tooltipText={t["product"]}
            defaultText={transaction.product ? transaction.product.name : ""}
            acceptChanges={(newValue) => {
                if (!transaction.product) {
                    transaction.product = {}
                }
                transaction.product.name = newValue
                transaction.product.id = null
                updateTransaction(transaction)
            }}
        />)}
        {renderCashTr(<ProfitButton
            isPositive={transaction["isPositive"]}
            onClick={(newValue) => {
                transaction["isPositive"] = newValue
                updateTransaction(transaction)
            }}
        />)}
        {renderCashTr(<InvisibleInputText
            tooltipText={t["value"]}
            defaultText={transaction.value}
            acceptChanges={(newValue) => {
                transaction.value = newValue
                updateTransaction(transaction)
            }}
        />)}
        {renderCashTr(<Comment
            comment={tempComment}
            setComment={setTempComment}
            onBlur={() => {
                if (tempComment !== transaction.comment) {
                    transaction.comment = tempComment;
                    updateTransaction(transaction);
                }
            }}
        />)}
        {renderCashTr(<ButtonComp
            icon={ICONS.delete}
            onClick={() => {
                deleteTransaction(transaction.id)
            }}
        />)}
    </tr>
}
