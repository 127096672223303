import style from './UserItem.module.css';
import React from "react";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const UserItem = ({ login, email, onClick }) => {
    const {t} = useAppData()
    return <div className={style.wrapper}>
        <div className={style.userSmallInfo} onClick={onClick}>
            <div>{t["login"]}: {login}</div>
            <div>{t["email"]}: {email}</div>
        </div>
    </div>
};