import React from 'react';
import style from './DoingsReportResult.module.css';
import ReportData from '../../../model/ReportData';
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {CombineReportDataByLabel, CombineReportDataByTask} from "../../../model/CombineReportData";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const DoingsReportResult = ({startDate, endDate}) => {
    const {t} = useAppData()
    const {data, update} = ReportData(startDate, endDate);

    return <div className={style.wrapper}>
        <div className={style.line}>
            <ButtonComp
                icon={ICONS.refresh}
                onClick={update}
            />
        </div>

        <table>
            <tbody>

            <tr>
                <td><b>{t["task"]}</b></td>
                <td><b>{t["duration"]}</b></td>
                <td><b>{t["percent"]}</b></td>
                <td><b>{t["count"]}</b></td>
                <td><b>{t["avg"]}</b></td>
            </tr>

            {CombineReportDataByTask(data).map(e => <tr key={e.name}>
                <td>{e.name}</td>
                <td>{Math.round(e.duration)}</td>
                <td>{Math.round(e.percent)}</td>
                <td>{e.count}</td>
                <td>{Math.round(e.duration / e.count)}</td>
            </tr>)}

            </tbody>
        </table>

        <table>
            <tbody>
            <tr>
                <td><b>{t["label"]}</b></td>
                <td><b>{t["duration"]}</b></td>
                <td><b>{t["percent"]}</b></td>
                <td><b>{t["count"]}</b></td>
                <td><b>{t["avg"]}</b></td>
            </tr>

            {CombineReportDataByLabel(data).map(e => <tr key={e.name}>
                <td>{e.name}</td>
                <td>{Math.round(e.duration)}</td>
                <td>{Math.round(e.percent)}</td>
                <td>{e.count}</td>
                <td>{e.count === 0 ? 0 : Math.round(e.duration / e.count)}</td>
            </tr>)}

            </tbody>
        </table>
    </div>
}
