import React from 'react';
import style from './DoingsTaskListLine.module.css';
import {CheckBox} from "../../../../../shared/ui/CheckBox";
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {elementComparatorByName} from "../../../../../shared/lib/Comparator";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {useModal} from "../../../../../shared/hooks/useModal";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const DoingsTaskListLine = ({element, taskActions, openLabelDialogue}) => {
    const {t} = useAppData()
    const {modal} = useModal()

    const modalContent = <div className={style.modal}>
        <h4>Are you sure you want to delete task '{element['name']}'?</h4>
        <div className={style.modalButtons}>
            <ButtonComp
                text={t["delete"]}
                onClick={() => {
                    taskActions.deleteTask(element)
                    modal.close()
                }}
            />
            <ButtonComp
                text={t["cancel"]}
                onClick={() => {
                    modal.close()
                }}
            />
        </div>
    </div>

    function onCheckedChanged(newVal) {
        element['checked'] = newVal
        taskActions.updateTask(element)
    }

    function onNameChanged(newVal) {
        if (element['name'] === newVal || !newVal) return;
        element['name'] = newVal
        taskActions.updateTask(element)
    }

    return <div className={style.wrapper}>

        <CheckBox defaultIsChecked={element['checked']}
                  onChange={onCheckedChanged}
        />

        <InputTextComp
            defaultText={element['name']}
            acceptChanges={onNameChanged}
        />

        <div className={style.labels}>
            {!!element['labels'] && element['labels']
                .sort(elementComparatorByName)
                .map((e) =>
                    <div key={e['id']} className={style.label}>{e['name']}</div>
                )}
        </div>

        <div className={style.buttons}>
            <ButtonComp
                icon={ICONS.options}
                onClick={() => {
                    openLabelDialogue(element)
                }}
            />
            <ButtonComp
                icon={ICONS.delete}
                onClick={() => {
                    modal.open(modalContent)
                }}
                tooltipText={t["doings_delete_task"]}
            />
        </div>
    </div>
}
