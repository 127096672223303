import React from 'react';
import style from './DoingsLabelList.module.css';
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {elementComparatorByName} from "../../../../../shared/lib/Comparator";
import {DoingsLabelListAddLine} from "../DoingsLabelListLine/DoingsLabelListAddLine";
import {DoingsLabelListLine} from "../DoingsLabelListLine/DoingsLabelListLine";
import {useModal} from "../../../../../shared/hooks/useModal";
import {LabelsData} from "../../../model/LabelsData";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const DoingsLabelList = ({currentTask, taskActions}) => {
    const {t} = useAppData()
    const {modal} = useModal()
    const {labels, labelActions} = LabelsData()

    return <div className={style.wrapper}>
        <div className={style.title}>
            <h3>{t["doings_select_labels"]}</h3>
        </div>

        <DoingsLabelListAddLine
            labelActions={labelActions}
        />

        <div className={style.list}>
            {labels
                .sort(elementComparatorByName)
                .map(e => <DoingsLabelListLine
                    key={e['id']}
                    label={e}
                    task={currentTask}
                    labelActions={labelActions}
                    taskActions={taskActions}
                />)}
        </div>

        <div className={style.modalButtons}>
            <ButtonComp
                text={t["cancel"]}
                onClick={() => {
                    modal.close()
                }}
            />
        </div>
    </div>
}