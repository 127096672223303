import style from "./Buttons.module.css";
import {ButtonComp} from "../../Button/ButtonComp";
import {ICONS} from "../../img/icons/icons";
import {dispatchGlobalEvent} from "../../../lib/Listener";
import {useAppData} from "../../../hooks/useAppData";

export const ClearStyleButton = ({refWorkSpace}) => {
    const {t} = useAppData()

    return <div className={style.clearButton}>
        <ButtonComp
            icon={ICONS.eraser}
            onClick={() => {
                document.execCommand('removeFormat', false,  '')
                refWorkSpace.current.focus()
                dispatchGlobalEvent("caretIsEdited")
            }}
            tooltipText={t["clear_styles"]}
        />
    </div>
}