import React, {useState} from 'react';
import style from './DoingsTaskListLine.module.css';
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {useAppData} from "../../../../../shared/hooks/useAppData";

export const DoingsTaskListAddLine = ({taskActions}) => {
    const {t} = useAppData()
    const [name, setName] = useState("")

    return <div className={style.simpleWrapper}>

        <ButtonComp
            icon={ICONS.plus}
            tooltipText={t["doings_create_task"]}
            onClick={() => {
                taskActions.createTask(name)
                setName("")
            }}
        />

        <InputTextComp
            title={t["doings_task_name"]}
            defaultText={name}
            acceptChanges={setName}
        />

    </div>
}
