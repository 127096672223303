import style from './Login.module.css';
import {ButtonComp} from "../../../shared/ui/Button/ButtonComp";
import {useRef} from "react";
import {signIn} from "../api/request";
import {useNavigate} from "react-router-dom";
import {InputTextComp} from "../../../shared/ui/InputText/InputTextComp";
import {useAppData} from "../../../shared/hooks/useAppData";

export const LoginPage = ({   setUserData,
                              setIsAuthenticated}) => {
    const {t} = useAppData()
    const history = useNavigate()
    const loginRef = useRef(null)
    const passwordRef = useRef(null)

    const onClickEnter = () => {
        const login = loginRef.current.value
        const password = passwordRef.current.value
        signIn(login, password, setIsAuthenticated, setUserData)
    }

    const onKeyPressTextField = (event) => {
        if (event.key === "Enter") {
            onClickEnter()
        }
    }

    return <div className={style.wrapper}>
        <div className={style.window}>
            <div className={style.title}><h2>{t["log_into"]}</h2></div>

            <div className={style.box}>
                <InputTextComp inputRef={loginRef} title={t["login"]} onKeyPress={onKeyPressTextField}/>
            </div>
            <div className={style.box}>
                <InputTextComp inputRef={passwordRef} title={t["password"]} type={"password"} onKeyPress={onKeyPressTextField}/>
            </div>

            <div className={style.panel}>
                <ButtonComp
                    text={t["log_in"]}
                    onClick={onClickEnter}
                />
            </div>
        </div>

        <div className={style.links}>
            <div className={style.link} onClick={() => history("/register")}>{t["i_don't_have_an_account"]}</div>
        </div>
    </div>
}