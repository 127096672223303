import style from "./Buttons.module.css";
import {ButtonComp} from "../../Button/ButtonComp";
import {ICONS} from "../../img/icons/icons";
import {dispatchGlobalEvent} from "../../../lib/Listener";
import {useAppData} from "../../../hooks/useAppData";

export const  NormalTextButton = ({refWorkSpace}) => {
    const {t} = useAppData()

    return <div className={style.button}>
        <ButtonComp
            icon={ICONS.text}
            onClick={() => {
                document.execCommand('formatBlock', false,  'div')
                refWorkSpace.current.focus()
                dispatchGlobalEvent("caretIsEdited")
            }}
            tooltipText={t["normal_text"]}
        />
    </div>
}