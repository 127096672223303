import style from './Login.module.css';
import {InputTextComp} from "../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../shared/ui/Button/ButtonComp";
import {useState} from "react";
import {register} from "../api/request";
import {useNavigate} from "react-router-dom";
import {useAppData} from "../../../shared/hooks/useAppData";

export const RegisterPage = () => {
    const {t} = useAppData()
    const [login, setLogin] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)

    const history = useNavigate()

    const clearFields = () => {
        setLogin("")
        setEmail("")
        setPassword("")
        setPasswordConfirm("")
    }

    const onClickEnter = () => {
        register(login, email, password, passwordConfirm, clearFields, setIsSuccess)
    }

    const onKeyPressTextField = (event) => {
        if (event.key === "Enter") {
            onClickEnter()
        }
    }

    return <div className={style.wrapper}>
        {!isSuccess ?
            <div>
                <div className={style.window}>
                    <div className={style.title}>
                        <h2>{t["new_user"]}</h2>
                    </div>
                    <div className={style.box}>
                        <InputTextComp
                            title={t["login"]}
                            acceptChanges={setLogin}
                            onKeyPress={onKeyPressTextField}
                        />
                    </div>
                    <div className={style.box}>
                        <InputTextComp
                            title={t["email"]}
                            acceptChanges={setEmail}
                            onKeyPress={onKeyPressTextField}
                        />
                    </div>
                    <div className={style.box}>
                        <InputTextComp
                            title={t["password"]}
                            acceptChanges={setPassword}
                            type={"password"}
                            onKeyPress={onKeyPressTextField}
                        />
                    </div>
                    <div className={style.box}>
                        <InputTextComp
                            title={t["password_confirm"]}
                            acceptChanges={setPasswordConfirm}
                            type={"password"}
                            onKeyPress={onKeyPressTextField}
                        />
                    </div>

                    <div className={style.panel}>
                        <ButtonComp
                            text={t["register"]}
                            onClick={() => {
                                onClickEnter()
                            }}
                        />
                    </div>
                </div>
                <div className={style.links}>
                    <div className={style.link} onClick={() => history("/login")}>{t["i_have_account"]}</div>
                </div>
            </div>
            :
            <div className={style.window}>
                <div className={style.title}>
                    <h2>Confirm account</h2>
                    <div className={style.box}>
                        <p>To complete the activation of your account, please check your
                            email for a confirmation message.
                        </p>
                    </div>
                </div>
            </div>
        }
    </div>
}