import {useEffect, useState} from "react";
import {isInsideTags} from "../../../lib/TextHtmlHelper";
import {addGlobalEventListener, dispatchGlobalEvent, removeGlobalEventListener} from "../../../lib/Listener";
import style from "./Buttons.module.css";
import {ButtonComp} from "../../Button/ButtonComp";
import {ICONS} from "../../img/icons/icons";
import {useAppData} from "../../../hooks/useAppData";

export const OrderedListButton = ({refWorkSpace}) => {
    const {t} = useAppData()
    const [activeButton, setActiveButton] = useState(false)
    const handleGlobalEvent = () => {
        setActiveButton(!!isInsideTags(refWorkSpace, "OL"))
    }

    useEffect(() => {

        addGlobalEventListener(handleGlobalEvent)

        return () => {
            removeGlobalEventListener(handleGlobalEvent)
        }
    }, [])

    return <div className={style.button}>
        <ButtonComp
            icon={ICONS.listOl}
            onClick={() => {
                document.execCommand('insertOrderedList', false,  '')
                refWorkSpace.current.focus()
                dispatchGlobalEvent("caretIsEdited")
            }}
            tooltipText={t["ordered_list"]}
            isActive={activeButton}
        />
    </div>
}