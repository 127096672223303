import React, {useEffect, useState} from "react";
import style from "./FeedingPage.module.css";
import {InputTextComp} from "../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../shared/ui/Button/ButtonComp";
import {createAccess, getAccessStatus, setInviteString} from "../api/request";
import {FeedingData} from "./parts/FeedingData/FeedingData";
import {useAppData} from "../../../shared/hooks/useAppData";

export const FeedingPage = () => {
    const {t} = useAppData()
    const [isPageCreated, setIsPageCreated] = useState(false)

    useEffect(() => {if (!isPageCreated) getAccessStatus(setIsPageCreated)}, [isPageCreated])

    if (isPageCreated)
        return <FeedingData/>
    else
        return <div className={style.wrapper}>
            <h2>{t["feeding_first_run"]}</h2>

            <div className={style.createNewButton}>
                <ButtonComp
                    text={t["create"]}
                    onClick={() => createAccess(setIsPageCreated)}
                />
            </div>
            {t["or"]}
            <InputTextComp
                title={t["use_access_string"]}
                hideOkBtn={false} // TODO: make this function
                acceptChanges={(newVal) => {
                    if (!!newVal)
                        setInviteString(newVal, setIsPageCreated)
                }}
            />

        </div>
}