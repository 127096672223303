import {useCooking} from "../../../lib/hooks/UseCooking";
import {useEffect, useState} from "react";
import style from "../CookingReceiptDetails/CookingNormalMode.module.css";
import {loadFile} from "../../../api/Request";
import {ProductLine} from "../ProductLine/ProductLine";
import {CookingEditModeHeader} from "./CookingEditModeHeader";
import {CookingEditModeDescription} from "./CookingEditModeDescription";
import {CookingEditModeAddProduct} from "./CookingEditModeAddProduct";
import {CookingEditModeResult} from "./CookingEditModeResult";
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {useAppData} from "../../../../../shared/hooks/useAppData";


export const CookingEditMode = ({receipt, setEditMode, setSelectedReceipt}) => {
    const {t} = useAppData()
    const {updateReceipt, dictionaryUnit, deleteProduct} = useCooking()
    const [dataFile, setDataFile] = useState([])

    useEffect(() => {
        loadFile(setDataFile, receipt.imageUrl)
    }, [receipt]);

    return <div className={style.wrapper}>
        <CookingEditModeHeader
            receipt={receipt}
            setEditMode={setEditMode}
        />
        <div className={style.created}>
            <div>
                <InputTextComp
                    title={t["group_name"]}
                    defaultText={receipt.groupName}
                    acceptChanges={(newValue) => {
                        receipt.groupName = newValue
                        updateReceipt(receipt)
                    }}
                />
            </div>
            <div>
                {t["created"]}: {new Date(receipt.created).toLocaleDateString()}
            </div>
        </div>
        <CookingEditModeDescription
            receipt={receipt}
            dataFile={dataFile}
        />
        <CookingEditModeAddProduct
            receipt={receipt}
            setSelectedReceipt={setSelectedReceipt}
        />
        {receipt["receiptProducts"].length > 0 &&
            <div className={style.viewProducts}>
                <table>
                    <thead>
                    <tr>
                        <th className={style.Th}>{t["product_name"]}</th>
                        <th className={style.Th}>{t["value"]}</th>
                        <th className={style.Th}>{t["unit"]}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {receipt["receiptProducts"].map((e, index) => (
                        <ProductLine
                            product={e}
                            key={index}
                            deleteProduct={deleteProduct}
                            updateReceipt={updateReceipt}
                            receipt={receipt}
                            dictionaryUnit={dictionaryUnit}
                        />
                    ))}
                    </tbody>
                </table>
            </div>
        }
        <CookingEditModeResult
            receipt={receipt}
        />
    </div>
}